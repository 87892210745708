var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auto-post-boosting"},[_c('rule-delete-modal',{on:{"delete-rule":_vm.deleteRule}}),_c('auto-post-boosting-navigation',{attrs:{"buttons":_vm.navigationButtons,"rule-id":_vm.ruleId,"service":_vm.service},on:{"reset-errors":_vm.resetErrors}}),_c('router-view',{staticClass:"auto-post-boosting__view",attrs:{"rule-id":_vm.ruleId,"service":_vm.service,"server-data":_vm.currentServerData,"data":_vm.currentData,"meta":_vm.currentMeta,"errors":_vm.errors},on:{"add-notification":_vm.addNotification,"reload-campaign-rule":function($event){_vm.getAutoPostBoostingCampaign().then($event)},"reload-ad-set-rule":function($event){_vm.getAutoPostBoostingAdSet().then($event)},"reload-ad-rule":function($event){_vm.getAutoPostBoostingAd().then($event)},"reload-rule":function($event){_vm.getAutoPostBoostingRule().then($event)},"set-error":_vm.setError,"remove-error":_vm.removeError},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('auto-post-boosting-header',{attrs:{"name":_vm.ruleData ? _vm.ruleData.name : 'Loading...'},on:{"close":_vm.close}})]},proxy:true},{key:"action",fn:function(ref){
var saveChanges = ref.saveChanges;
var frontEndErrorsHandler = ref.frontEndErrorsHandler;
var savingDisabled = ref.savingDisabled; if ( savingDisabled === void 0 ) savingDisabled = false;
return [_c('auto-post-boosting-action',{attrs:{"toggling-disabled":!_vm.ruleId || _vm.saving || _vm.sending || (_vm.ruleServerData && _vm.ruleServerData.status === 'IN_DRAFT'),"deleting-disabled":!_vm.ruleId || _vm.saving || _vm.sending,"saving-disabled":!!Object.values(_vm.errors).filter(function (_) { return !!_; }).length || !_vm.ruleId || _vm.saving ||
          _vm.sending || !_vm.currentServerData || savingDisabled,"saving":_vm.saving},on:{"save-changes-click":function($event){return _vm.saveChangesWrapped(saveChanges, frontEndErrorsHandler)},"close":_vm.close}})]}},{key:"footer",fn:function(ref){
          var nextStep = ref.nextStep;
          var frontEndErrorsHandler = ref.frontEndErrorsHandler;
          var disabled = ref.disabled; if ( disabled === void 0 ) disabled = false;
return [_c('auto-post-boosting-footer',{attrs:{"disabled":!!Object.values(_vm.errors).filter(function (_) { return !!_; }).length || _vm.saving || _vm.sending ||
          (!!_vm.ruleId && !_vm.currentServerData) || disabled,"routes":_vm.routes,"sending":_vm.sending},on:{"reset-errors":_vm.resetErrors,"next-step-click":function($event){return _vm.nextStepWrapped(nextStep, frontEndErrorsHandler, $event)},"close":_vm.close}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }