
















import Vue from 'vue'

import Modal from '../../../components/Modal.vue'

export default Vue.extend({
  name: 'folderDeleteFolder',
  components: { Modal },
  props: ['ruleId'],
  methods: {
    deleteRule () {
      this.$emit('delete-rule', this.ruleId)
    }
  }
})
