import {
  CONDITION_TYPE_CONDITION,
  CONDITION_TYPE_GROUP,
  METRIC_TYPE_SIMPLE,
  OPERATOR_GREATER_THAN
} from '@/constants/FbAutomatedRule'

export const defaultGroup = {
  type: CONDITION_TYPE_GROUP,
  level: 1,
  id: null,
  uid: null,
  operator: 'AND',
  conditions: null
}

export function generateConditionWithId (id = Date.now() + 1): unknown {
  return {
    id,
    uid: id,
    type: CONDITION_TYPE_CONDITION,
    metric_type: METRIC_TYPE_SIMPLE,
    metric: null,
    custom_metric_id: null,
    value: 0,
    operator: OPERATOR_GREATER_THAN,
    time_range: 'LIFETIME'
  }
}
