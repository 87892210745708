





























































import type { UnionServices } from '@/models/IUserUses'
import { defaultGroup, generateConditionWithId } from '@/utils/autoPostBoostingConditions'
import type { TJsonApiBody } from 'jsona/lib/JsonaTypes'
import type { AxiosResponse } from 'axios'
import type { Location } from 'vue-router'
import PostBoostingService from '@/services/api/PostBoostingService'
import AutoPostBoostingAction from '@/components/AutoPostBoosting/AutoPostBoostingAction.vue'
import AutoPostBoostingFooter from '@/components/AutoPostBoosting/AutoPostBoostingFooter.vue'
import AutoPostBoostingHeader from '@/components/AutoPostBoosting/AutoPostBoostingHeader.vue'
import AutoPostBoostingNavigation, {
  AutoPostBoostingNavigationButton
} from '@/components/AutoPostBoosting/AutoPostBoostingNavigation.vue'
import RuleDeleteModal from '@/components/strategies/modal/RuleDeleteModal.vue'
import axios from 'axios'
import Jsona from 'jsona'
import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'

export type AutoPostBoostingChildrenRoute = {
  path: string,
  name: string,
  meta: {
    name: string
  }
}

export type AutoPostBoostingRuleData = {
  ad_account_id: number,
  boosted_posts_count: number,
  id: string,
  name: string,
  page_id: number,
  status: 'IN_DRAFT' | string,
  conditions: unknown,
  post_types: Array<string> | null
}

export type AutoPostBoostingError = {
  text: string | Array<string>,
  list?: Array<AutoPostBoostingError>
  deep?: Record<string, AutoPostBoostingError>
}

export type AutoPostBoostingErrors = Record<string, AutoPostBoostingError>

type Data = {
  saving: boolean,
  sending: boolean,
  routes: Array<AutoPostBoostingChildrenRoute>,
  errors: AutoPostBoostingErrors,
  rulePromise: Promise<AxiosResponse<AutoPostBoostingRuleData>> | null,
  ruleServerData: AutoPostBoostingRuleData | null,
  ruleData: AutoPostBoostingRuleData,
  ruleCampaignPromise: Promise<AxiosResponse> | null,
  ruleCampaignServerData: unknown | null,
  ruleCampaignServerMeta: unknown | null,
  ruleCampaignData: unknown,
  ruleAdSetPromise: Promise<AxiosResponse> | null,
  ruleAdSetServerData: unknown | null,
  ruleAdSetServerMeta: unknown | null,
  ruleAdSetData: unknown,
  ruleAdPromise: Promise<AxiosResponse> | null,
  ruleAdServerData: unknown | null,
  ruleAdServerMeta: unknown | null,
  ruleAdData: unknown
}

const jsona = new Jsona()

function defaultRuleData (ruleId) {
  const dateNow = Date.now()
  return ruleId ? null : {
    ad_account_id: NaN,
    boosted_posts_count: 10,
    id: '',
    name: 'Untitled rule',
    page_id: NaN,
    status: '',
    conditions: {
      ...defaultGroup,
      id: dateNow,
      uid: dateNow,
      conditions: { [dateNow + 1]: generateConditionWithId(dateNow + 1) }
    },
    post_types: null
  }
}

function defaultRuleCampaignData () {
  return {
    name: '',
    objective: null,
    spend_limit: null,
    bidding: null,
    budget_amount: null,
    budget_type: null,
    bid: null
  }
}

export default Vue.extend({
  name: 'AutoPostBoostingEdit',
  components: {
    AutoPostBoostingFooter,
    AutoPostBoostingAction,
    AutoPostBoostingHeader,
    AutoPostBoostingNavigation,
    RuleDeleteModal
  },
  props: {
    service: {
      type: String as PropType<UnionServices>,
      required: true
    },
    ruleId: {
      type: String as PropType<`${number}`>,
      default: null
    }
  },
  data (): Data {
    const { ruleId } = this.$route.params
    const data: Data = {
      saving: false,
      sending: false,
      routes: null,
      errors: {
        ad_account_id: undefined,
        boosted_posts_count: undefined,
        name: undefined,
        page_id: undefined,
        conditions: undefined,
        post_types: undefined,
        audiences_data: undefined,
        'ad_placements.devices': undefined,
        'ad_placements.platforms': undefined,
        'ad_placements.positions': undefined,
        url: undefined,
        url_parameter: undefined,
        notification_spec: undefined
      },
      rulePromise: null,
      ruleServerData: null,
      ruleData: defaultRuleData(ruleId),
      ruleCampaignPromise: null,
      ruleCampaignServerData: null,
      ruleCampaignServerMeta: null,
      ruleCampaignData: defaultRuleCampaignData(),
      ruleAdSetPromise: null,
      ruleAdSetServerData: null,
      ruleAdSetServerMeta: null,
      ruleAdSetData: {
        name: '',
        days_for_promote_ads: 1,
        budget: 10,
        optimize_for: '',
        pay_for: '',
        bidding: 'LOWEST_COST_WITHOUT_CAP',
        budget_type: 1,
        bid: 0,
        audience_fb_id: '',
        placements: null
      },
      ruleAdPromise: null,
      ruleAdServerData: null,
      ruleAdServerMeta: null,
      ruleAdData: {
        call_to_action: null,
        url_parameter: '',
        url: '',
        notification_spec: {
          enabled: false,
          emails: [],
          slack_channels: []
        },
        pixel_id: null,
        conversion_event: null
      }
    }
    return data
  },
  computed: {
    currentData (): unknown | null {
      if (this.$route.meta.name === 'Details' || this.$route.meta.name === 'Filters') {
        return this.ruleData
      }
      if (this.$route.meta.name === 'Campaign') {
        return this.ruleCampaignData
      }
      if (this.$route.meta.name === 'Ad Set') {
        return this.ruleAdSetData
      }
      if (this.$route.meta.name === 'Ad') {
        return this.ruleAdData
      }
      return null
    },
    currentMeta (): unknown | null {
      if (this.$route.meta.name === 'Campaign') {
        return this.ruleCampaignServerMeta
      }
      if (this.$route.meta.name === 'Ad Set') {
        return this.ruleAdSetServerMeta
      }
      if (this.$route.meta.name === 'Ad') {
        return this.ruleAdServerMeta
      }
      return null
    },
    currentServerData: {
      get (): unknown | null {
        if (this.$route.meta.name === 'Details' || this.$route.meta.name === 'Filters') {
          return this.ruleServerData
        }
        if (this.$route.meta.name === 'Campaign') {
          return this.ruleCampaignServerData
        }
        if (this.$route.meta.name === 'Ad Set') {
          return this.ruleAdSetServerData
        }
        if (this.$route.meta.name === 'Ad') {
          return this.ruleAdServerData
        }
        return null
      },
      set (data: unknown | null): void {
        if (this.$route.meta.name === 'Details' || this.$route.meta.name === 'Filters') {
          this.ruleServerData = data
        } else if (this.$route.meta.name === 'Campaign') {
          this.ruleCampaignServerData = data
        } else if (this.$route.meta.name === 'Ad Set') {
          this.ruleAdSetServerData = data
        } else if (this.$route.meta.name === 'Ad') {
          this.ruleAdServerData = data
        }
      }
    },
    navigationButtons (): Array<AutoPostBoostingNavigationButton> {
      let prevDone = false
      return this.routes.map(_ => {
        let loading = !!this.ruleId
        let done = false
        const name = _.meta.name

        if (loading) {
          switch (name) {
            case 'Details':
              loading = !this.ruleServerData
              done = loading ? false : !!this.ruleServerData?.name
              break
            case 'Filters':
              loading = !this.ruleServerData
              done = loading ? false : !!this.ruleServerData?.conditions
              break
            case 'Campaign':
              loading = !this.ruleCampaignServerData
              done = loading ? false : !!this.ruleCampaignServerData?.name
              break
            case 'Ad Set':
              loading = !this.ruleAdSetServerData
              done = loading ? false : !!this.ruleAdSetServerData?.name
              break
            case 'Ad':
              loading = !this.ruleAdServerData
              done = loading ? false : !!this.ruleAdServerData?.url_parameter
              break
          }
        }

        return {
          name,
          route: _,
          loading,
          disabled: (!done && !prevDone) || (this.ruleId ? _.name === this.$route.name : true),
          current: _.name === this.$route.name,
          done: (prevDone = done)
        }
      })
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    async sendData (
      sendChanges: () => Promise<AxiosResponse<unknown> | null>,
      publish?: boolean
    ): Promise<AxiosResponse<unknown> | null> {
      const previousServerData = this.currentServerData
      try {
        this.currentServerData = null
        const axiosResponse: AxiosResponse<TJsonApiBody> = await sendChanges()
        this.currentServerData = axiosResponse ? jsona.deserialize(axiosResponse.data) : previousServerData
        if (publish) {
          this.addNotification({
            body: `Congrats, rule succesed “${this.ruleData.name}” created`,
            type: 'success'
          })
        } else {
          this.addNotification({
            body: `Auto Post Boosting rule ${this.$route.meta.name} successfully ${previousServerData ? 'saved' : 'created'}`,
            type: 'success'
          })
        }
        return this.currentServerData
      } catch (error) {
        this.currentServerData = previousServerData
        throw error
      }
    },
    catchSendData (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response.status === 500) {
          this.addNotification({
            title: `Failed to save AutoPostBoosting rule ${this.$route.meta.name}.`,
            body: 'System error.',
            type: 'danger'
          })
        } else {
          if ((error.response.data as any)?.errors) {
            for (const { source, detail } of (error.response.data as any).errors) {
              for (const body of detail) {
                this.addNotification({
                  body: body.startsWith(source.parameter) &&
                    body.startsWith('conditions.') ? body.replace(source.parameter, 'Condition') : body,
                  type: 'danger'
                })
              }
              this.setError(source.parameter, detail)
            }
          }
        }
      }
    },
    async saveChangesWrapped (
      saveChanges: () => Promise<AxiosResponse<unknown> | null>,
      frontEndErrorsHandler: () => boolean
    ): Promise<unknown> {
      if (frontEndErrorsHandler()) {
        return
      }
      try {
        this.saving = true
        await this.sendData(saveChanges)
      } catch (error) {
        this.catchSendData(error)
      } finally {
        this.saving = false
      }
    },
    nextRoute (previousRuleId, serverData, nextRoute) {
      if (serverData.type === 'post_boosting_rules' && previousRuleId !== serverData.id) {
        this.$router.push({
          name: 'PostBoostingEditFilters',
          params: { service: this.service, ruleId: serverData.id }
        }).then(() => {
          this.routes = this.getRoutes()
          this.getAutoPostBoostingCampaign()
          this.getAutoPostBoostingAdSet()
          this.getAutoPostBoostingAd()
        })
      } else if (nextRoute) {
        this.$router.push({
          ...nextRoute,
          params: { service: this.service, ruleId: this.ruleId }
        })
      }
    },
    async nextStepWrapped (
      nextStep: () => Promise<AxiosResponse<unknown> | null>,
      frontEndErrorsHandler: () => boolean,
      nextRoute: Location
    ): Promise<unknown> {
      if (frontEndErrorsHandler()) {
        return
      }
      try {
        this.sending = true
        const data = await this.sendData(nextStep, nextRoute?.name === 'PostBoosting')
        this.nextRoute(this.ruleId, data, nextRoute)
      } catch (error) {
        this.catchSendData(error)
      } finally {
        this.sending = false
      }
    },
    getRoutes (): Array<AutoPostBoostingChildrenRoute> {
      if (this.$route.name === 'PostBoostingNew') {
        return this.$router.options.routes.find(_ => _.name === 'PostBoostingNew').children
      }
      if (this.$route.name === 'EditPostBoosting') {
        return this.$router.options.routes.find(_ => _.name === 'EditPostBoosting').children
      }
      return this.$router.options.routes.find(_ => _.children?.find(_ => _.name === this.$route.name)).children
    },
    close (): void {
      this.$router.push({
        name: 'PostBoosting',
        service: this.$route.params.service
      })
    },
    async getAutoPostBoostingCampaign (): Promise<AxiosResponse> {
      this.ruleCampaignServerData = null
      if (this.ruleCampaignPromise) {
        return this.ruleCampaignPromise
      }
      try {
        this.ruleCampaignPromise = PostBoostingService.getCampaignSettings(this.service, this.ruleId)
        const axiosResponse: AxiosResponse<TJsonApiBody & { meta: unknown }> = await this.ruleCampaignPromise
        this.ruleCampaignPromise = null
        this.ruleCampaignServerMeta = axiosResponse.status === 200 ? axiosResponse.data.meta : null
        this.ruleCampaignServerData = axiosResponse.status === 200 ? jsona.deserialize(axiosResponse.data) : {}
        this.ruleCampaignData.name = this.ruleCampaignServerData?.name_template || ''
        this.ruleCampaignData.objective = this.ruleCampaignServerData?.objective || null
        this.ruleCampaignData.spend_limit = this.ruleCampaignServerData?.spend_limit || null
        this.ruleCampaignData.bidding = this.ruleCampaignServerData?.bidding || null
        this.ruleCampaignData.budget_amount = this.ruleCampaignServerData?.budget_amount || null
        this.ruleCampaignData.budget_type = this.ruleCampaignServerData?.budget_type || null
        this.ruleCampaignData.bid = this.ruleCampaignServerData?.bid || null
        return axiosResponse
      } catch (error) {
        let body = 'Unknown error.'
        if (axios.isAxiosError(error)) {
          if (error.response.status === 500) {
            body = 'System error.'
          }
        }
        this.addNotification({
          title: 'Failed to load AutoPostBoosting rule.',
          body,
          type: 'danger'
        })
        throw error
      }
    },
    async getAutoPostBoostingAdSet (): Promise<AxiosResponse> {
      this.ruleAdSetServerData = null
      if (this.ruleAdSetPromise) {
        return this.ruleAdSetPromise
      }
      try {
        this.ruleAdSetPromise = PostBoostingService.getAdSetSettings(this.service, this.ruleId)
        const axiosResponse: AxiosResponse<TJsonApiBody & { meta: unknown }> = await this.ruleAdSetPromise
        this.ruleAdSetPromise = null
        this.ruleAdSetServerData = axiosResponse.status === 200 ? jsona.deserialize(axiosResponse.data) : {}
        this.ruleAdSetServerMeta = axiosResponse.status === 200 ? axiosResponse.data.meta : null
        if (this.ruleAdSetServerData) {
          this.ruleAdSetData.name = this.ruleAdSetServerData.name || ''
          this.ruleAdSetData.days_for_promote_ads = this.ruleAdSetServerData.days_for_promote_ads || 1
          this.ruleAdSetData.budget = this.ruleAdSetServerData.budget || 10
          this.ruleAdSetData.optimize_for =
            this.ruleAdSetServerData.optimize_for || this.ruleAdSetServerMeta?.optimize_for[0] || ''
          this.ruleAdSetData.pay_for =
            this.ruleAdSetServerData.pay_for ||
            (this.ruleAdSetServerMeta?.pay_for?.[this.ruleAdSetData.optimize_for]?.[0]) || ''
          this.ruleAdSetData.bidding = this.ruleAdSetServerData.bidding || 'LOWEST_COST_WITHOUT_CAP'
          this.ruleAdSetData.budget_type = this.ruleAdSetServerData.budget_type || 1
          this.ruleAdSetData.bid =
            this.ruleAdSetServerData.bid ||
            (this.ruleAdSetData.bidding === 'LOWEST_COST_WITHOUT_CAP' ? null : 10)
          if (this.ruleAdSetServerData.formatted_targeting) {
            const { formatted_targeting } = this.ruleAdSetServerData
            this.ruleAdSetData.audience_fb_id = formatted_targeting.audiences?.[0]?.fb_id?.toString() || ''
            this.ruleAdSetData.placements = formatted_targeting.ad_placements?.devices ? {
              ...formatted_targeting.ad_placements,
              positions: Object.values(formatted_targeting.ad_placements.positions)
                .reduce(($: Array<string>, _: Array<string>) => [...$, ..._], [])
            } : null
          }
        }
        return axiosResponse
      } catch (error) {
        let body = 'Unknown error.'
        if (axios.isAxiosError(error)) {
          if (error.response.status === 500) {
            body = 'System error.'
          }
        }
        this.addNotification({
          title: 'Failed to load AutoPostBoosting rule.',
          body,
          type: 'danger'
        })
        throw error
      }
    },
    async getAutoPostBoostingAd (): Promise<AxiosResponse> {
      this.ruleAdServerData = null
      if (this.ruleAdPromise) {
        return this.ruleAdPromise
      }
      try {
        this.ruleAdPromise = PostBoostingService.getAdSettings(this.service, this.ruleId)
        const axiosResponse: AxiosResponse<TJsonApiBody & { meta: unknown }> = await this.ruleAdPromise
        this.ruleAdPromise = null
        this.ruleAdServerData = axiosResponse.status === 200 ? jsona.deserialize(axiosResponse.data) : {}
        this.ruleAdServerMeta = axiosResponse.status === 200 ? axiosResponse.data.meta : null
        if (this.ruleAdServerData) {
          this.ruleAdData.url = this.ruleAdServerData.url || ''
          this.ruleAdData.url_parameter = this.ruleAdServerData.url_parameter || ''
          this.ruleAdData.call_to_action =
            this.ruleAdServerData.call_to_action || this.ruleAdServerMeta?.call_to_actions[0]?.key || null
          this.ruleAdData.conversion_event = this.ruleAdServerData.conversion_event || null
          this.ruleAdData.pixel_id = this.ruleAdServerData.pixel_id || null
        }
        return axiosResponse
      } catch (error) {
        let body = 'Unknown error.'
        if (axios.isAxiosError(error)) {
          if (error.response.status === 500) {
            body = 'System error.'
          }
        }
        this.addNotification({
          title: 'Failed to load AutoPostBoosting rule.',
          body,
          type: 'danger'
        })
        throw error
      }
    },
    async getAutoPostBoostingRule (): Promise<AxiosResponse> {
      this.ruleServerData = null
      this.ruleData = null
      if (this.rulePromise) {
        return this.rulePromise
      }
      try {
        this.rulePromise = PostBoostingService.getPostBoostingRule(this.service, this.ruleId)
        const axiosResponse: AxiosResponse<TJsonApiBody> = await this.rulePromise
        this.rulePromise = null
        const data = axiosResponse.status === 200 ? jsona.deserialize(axiosResponse.data) : {}
        this.ruleServerData = data
        const dateNow = Date.now()
        this.ruleData = {
          ad_account_id: data.ad_account_id,
          boosted_posts_count: data.boosted_posts_count,
          id: data.id,
          page_id: data.page_id,
          name: data.name,
          status: data.status,
          conditions: data.conditions || {
            ...defaultGroup,
            id: dateNow,
            uid: dateNow,
            conditions: { [dateNow + 1]: generateConditionWithId(dateNow + 1) }
          },
          post_types: data.post_types
        }
        return axiosResponse
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response.status === 500) {
            this.addNotification({
              body: 'System error',
              type: 'danger'
            })
          }
          if (error.response.status === 404) {
            this.$router.push({ name: '404' }).then()
          }
        }
        throw error
      }
    },
    setError (key: string, newError: string | Array<string> | AutoPostBoostingError) {
      if (key.startsWith('conditions.')) {
        const id = parseInt(key.slice(22))

        if (id) {
          if (!this.errors.conditions) {
            this.errors.conditions = {
              text: 'Error',
              deep: {}
            }
          }

          if (!this.errors.conditions.deep[id]) {
            this.errors.conditions.deep[id] = { text: newError }
          }
        }
      } else {
        if (typeof newError === 'string' || Array.isArray(newError)) {
          if (this.errors[key]) {
            this.errors[key].text = newError
          } else {
            this.errors[key] = { text: newError }
          }
        } else {
          this.errors[key] = newError
        }
      }
    },
    removeError (key: string) {
      if (key.startsWith('conditions.')) {
        const id = +key.slice(11)

        if (id && this.errors.conditions) {
          this.errors.conditions.deep[id] = undefined

          if (Object.values(this.errors.conditions.deep).filter(_ => !!_).length === 0) {
            this.errors.conditions = undefined
          }
        }
      } else {
        this.errors[key] = undefined
      }
    },
    resetErrors () {
      for (const key in this.errors) {
        this.errors[key] = undefined
      }
    },
    async deleteRule (): Promise<void> {
      try {
        await PostBoostingService.deletePostBoostingRule(this.service, this.ruleId)
        this.$router.push({ name: 'PostBoosting' }).then()
      } catch (error) {
        this.addNotification({
          body: 'Failed to delete AutoPostBoosting rule.',
          type: 'danger'
        })
      }
    }
  },
  created (): void {
    if (this.$route.name === 'PostBoostingNew') {
      this.$router.replace({ name: 'PostBoostingNewDetails', params: { service: this.service } })
    }
    if (this.$route.name === 'EditPostBoosting') {
      this.$router.replace({ name: 'PostBoostingEditDetails', params: { service: this.service, ruleId: this.ruleId } })
    }
    this.routes = this.getRoutes()
    if (this.ruleId !== null) {
      this.getAutoPostBoostingRule()
        .then(() => {
          if (this.$route.name !== 'PostBoostingEditFilters' &&
            this.$route.name !== 'PostBoostingEditDetails' &&
            !this.ruleData.conditions
          ) {
            // this.addNotification({ body: 'The rule has not been created yet!', type: 'danger' })
            this.$router.replace({
              name: 'PostBoostingEditFilters',
              params: { ...this.$route.params }
            })
          }
          this.getAutoPostBoostingCampaign()
          this.getAutoPostBoostingAdSet()
          this.getAutoPostBoostingAd()
        })
    }
  }
})
