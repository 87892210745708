










































import NewUiSpinner from '@/components/NewUIComponents/NewUiSpinner.vue'
import NewUiSwitcher from '@/components/NewUIComponents/NewUiSwitcher.vue'
import Vue from 'vue'

const booleanDefaultFalse = {
  type: Boolean,
  default: false
} as const

export default Vue.extend({
  name: 'AutoPostBoostingAction',
  components: {
    NewUiSpinner,
    NewUiSwitcher
  },
  props: {
    togglingDisabled: booleanDefaultFalse,
    deletingDisabled: booleanDefaultFalse,
    savingDisabled: booleanDefaultFalse,
    saving: booleanDefaultFalse
  }
})
