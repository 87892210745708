













import Vue, { PropType } from 'vue'

type SpinnerType = 'grow' | 'border'

const COLOR_MODIFIERS = [
  'primary', 'success', 'danger', 'warning',
  'orange', 'green', 'gray', 'blue',
  'dark-yellow', 'dark', 'red', 'dark-blue'
] as const

export default Vue.extend({
  name: 'NewUiSpinner',
  props: {
    type: {
      type: String as PropType<SpinnerType>,
      default: 'border'
    },
    sm: {
      type: Boolean,
      default: false
    },
    color: {
      type: String as PropType<typeof COLOR_MODIFIERS[number] | string>,
      default: null
    }
  },
  computed: {
    classes (): Array<string> {
      const result = [`spinner-${this.type}`]

      if (this.sm) {
        result.push(result[0] + '-sm')
      }

      if (this.color && COLOR_MODIFIERS.includes(this.color)) {
        result.push(`${this.color}-text`)
      }

      return result
    },
    styles (): Record<string, string> | undefined {
      if (!this.color || COLOR_MODIFIERS.includes(this.color)) {
        return undefined
      }
      return {
        color: this.color
      }
    }
  }
})
