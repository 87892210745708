

















import Vue from 'vue'

export default Vue.extend({
  name: 'AutoPostBoostingHeader',
  props: {
    name: {
      type: String,
      default: 'Untitled name'
    }
  }
})
