































import NewUiSpinner from '@/components/NewUIComponents/NewUiSpinner.vue'
import type { AutoPostBoostingChildrenRoute } from '@/views/AutoPostBoosting/AutoPostBoostingEdit.vue'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'AutoPostBoostingFooter',
  components: { NewUiSpinner },
  props: {
    routes: {
      type: Array as PropType<Array<AutoPostBoostingChildrenRoute>>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: true
    },
    sending: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    current (): AutoPostBoostingChildrenRoute {
      return this.routes.find(_ => _.name === this.$route.name)
    },
    currentId (): number {
      return this.routes.indexOf(this.current)
    },
    previous (): AutoPostBoostingChildrenRoute | undefined {
      return this.currentId >= 1 ? this.routes.at(this.currentId - 1) : undefined
    },
    nextstep (): AutoPostBoostingChildrenRoute | undefined {
      return this.currentId === 4
        ? { name: 'PostBoosting' }
        : (
          this.currentId >= 0
            ? this.routes.at(this.currentId + 1)
            : undefined
        )
    }
  },
  methods: {
    clickPrevious (): void {
      this.$emit('reset-errors')
      this.$router.push({ ...this.previous, params: { ...this.$route.params } })
    }
  }
})
