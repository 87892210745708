





































import NewUiSpinner from '@/components/NewUIComponents/NewUiSpinner.vue'
import type { UnionServices } from '@/models/IUserUses'
import type { AutoPostBoostingChildrenRoute } from '@/views/AutoPostBoosting/AutoPostBoostingEdit.vue'
import type { PropType } from 'vue'
import Vue from 'vue'

export type AutoPostBoostingNavigationButton = {
  name: string,
  route: AutoPostBoostingChildrenRoute,
  loading: boolean,
  disabled: boolean,
  current: boolean,
  done: boolean
}

type Props = {
  buttons: Array<AutoPostBoostingNavigationButton>
}

export default Vue.extend({
  name: 'AutoPostBoostingNavigation',
  components: { NewUiSpinner },
  data: () => ({ once: false, left: 0, width: 0 }),
  watch: {
    buttons () {
      this.recalculateCurrentPosition()
    }
  },
  props: {
    buttons: {
      type: Array as PropType<Props['buttons']>,
      required: true
    },
    service: {
      type: String as PropType<UnionServices>,
      required: true
    },
    ruleId: {
      type: String as PropType<`${number}`>,
      default: null
    }
  },
  methods: {
    recalculateCurrentPosition (): void {
      const index = this.buttons.findIndex(_ => _.current)
      if (index !== -1) {
        this.width = this.$el.children.item(index).clientWidth
        this.left = [...this.$el.children].slice(0, index).reduce((a, _) => a + _.clientWidth + 8, 0)
      }
    },
    _class (button: AutoPostBoostingNavigationButton): Record<string, boolean> {
      return {
        'auto-post-boosting-navigation__button_disabled': button.disabled,
        'auto-post-boosting-navigation__button_done': button.done,
        'auto-post-boosting-navigation__button_current': button.current
      }
    },
    click (button: AutoPostBoostingNavigationButton): void {
      this.$emit('reset-errors')
      this.$router.push({
        name: button.route.name,
        params: { service: this.service, ruleId: this.ruleId }
      })
    },
    developClick (button: AutoPostBoostingNavigationButton): void {
      this.$emit('reset-errors')
      if (!button.current) {
        this.$router.push({
          name: button.route.name,
          params: { service: this.service, ruleId: this.ruleId }
        })
      }
    }
  },
  mounted () {
    requestAnimationFrame(() => {
      this.recalculateCurrentPosition()
      this.once = true
    })
  }
})
